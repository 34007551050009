<template>
  <div class="calendar">
    <CalendarHeader
      :month="month"
      :year="year"
      @goPrev="goPrev"
      @goNext="goNext"
    ></CalendarHeader>
    <Select
      v-if="showFilter"
      v-model="therapeutic_areas"
      :items="specialties"
      :placeholder="'Выберите терапевтическую область'"
      class="mb-4"
      @change="onMainFilterChange(therapeutic_areas_YM)"
    />
    <CalendarBody
      :currentDay="currentDay"
      :year="year"
      :month="month"
      :multiply="multiply"
      :freeChoice="freeChoice"
      @dayClick="dayClick"
    />
  </div>
</template>
 
<script>
import Select from "@/components/form-elements/Select.vue";
import CalendarBody from "./calendarBody.vue";
import CalendarHeader from "./calendarHeader.vue";
import { mapActions, mapGetters } from "vuex";
import filter from "@/mixins/filter";
export default {
  name: "EventsCalendar",
  mixins: [filter],
  props: {
    showFilter: {
      type: Boolean,
      default: true,
    },
    notSetQuery: {
      type: Boolean,
      default: false,
    },
    freeChoice: {
      type: Boolean,
      default: false,
    },
    multiply: {
      type: Boolean,
      default: false,
    },
    specialtiesList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data: () => ({
    date: new Date(),
    currentDay: null,
    months: [
      "Январь",
      "Февраль",
      "Март",
      "Апрель",
      "Май",
      "Июнь",
      "Июль",
      "Август",
      "Сентябрь",
      "Октябрь",
      "Ноябрь",
      "Декабрь",
    ],
  }),
  components: { CalendarHeader, CalendarBody, Select },
  computed: {
    ...mapGetters(["NosologysFilter"]),
    specialties() {
      let nosologys = this.specialtiesList.length ? this.NosologysFilter.filter((el) =>
        this.specialtiesList.includes(el.slug)
      ) : this.NosologysFilter
      let arr = [];
      arr = nosologys.map((el) => ({
        label: el.title,
        value: el.slug || el.id,
      }));
      arr.unshift({
        label: "Все",
        value: "",
      });
      return arr;
    },
    year() {
      return this.date.getFullYear();
    },
    month() {
      return this.date.getMonth();
    },
    therapeutic_areas_YM() {
      let arr = [];
      this.therapeutic_areas.forEach((i) => {
        let find = this.specialties.find((el) => el.value === i);
        if (find) {
          arr.push(find.label);
        }
      });
      return arr;
    },
  },
  methods: {
    ...mapActions(["fetchNosologysFilter", "fetchEvents"]),
    goNext() {
      let month = this.date.getMonth() + 1;
      this.date = new Date(this.date.setMonth(month));
      this.$emit("goNext", this.months[this.month]);
    },
    goPrev() {
      let month = this.date.getMonth() - 1;
      this.date = new Date(this.date.setMonth(month));
      this.$emit("goPrev", this.months[this.month]);
    },
    async dayClick(day) {
      if (!this.multiply) {
        if (day) {
          this.currentDay = [day.date];
          this.updateDateAfter(this.$m(day.date).format("YYYY-MM-DD"));
          this.updateDateBefore(this.$m(day.date).format("YYYY-MM-DD"));
          // await this.fetchEvents(this.filter);
        } else {
          this.currentDay = null;
          this.updateDateAfter(null);
          this.updateDateBefore(null);
          //
        }
      } else {
        if (day) {
          if (!this.currentDay || this.currentDay.length !== 1) {
            this.currentDay = [];
          }
          this.currentDay.splice(
            this.currentDay.length === 1 ? 1 : 0,
            0,
            day.date
          );
          if (this.currentDay.length === 1) {
            this.updateDateAfter(this.$m(day.date).format("YYYY-MM-DD"));
          } else {
            this.updateDateBefore(this.$m(day.date).format("YYYY-MM-DD"));
          }
          // await this.fetchEvents(this.filter);
        } else if (this.currentDay.length === 2) {
          this.currentDay = null;
          this.updateDateAfter(null);
          this.updateDateBefore(null);
          //
        }
      }
      // await this.fetchEvents(this.filter);
      this.$emit("dayClick");
    },
    onMainFilterChange(value) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "main calendar areas fllter use", {
          "main calendar areas fllter use": {
            "area name": `${value.join(",")}`,
            ...this.$root.ymFields,
          },
        });
      }
    },
  },
  async mounted() {
    await this.fetchNosologysFilter();
    if (this.date_before && !this.multiply) {
      this.currentDay = [new Date(this.date_before + "T00:00:00")];
    }
    if (!Object.keys(this.filter).length) {
      // this.fetchEvents();
      this.$emit("updFilter");
    }
  },
  created() {},
  watch: {
    date_before() {
      if (!this.notSetQuery) {
        this.buildQuery();
      }
    },
    async therapeutic_areas(o, n) {
      if (JSON.stringify(o) !== JSON.stringify(n)) {
        this.currentDay = null;
      }
    },
    async types(o, n) {
      if (JSON.stringify(o) !== JSON.stringify(n)) {
        this.currentDay = null;
      }
    },

    async filter(n, o) {
      if (!Object.keys(o).length) {
        let updFilter = { ...this.filter };
        delete updFilter.date_before;
        delete updFilter.date_after;
        this.$emit("updFilter", updFilter);
        this.$emit("updFilter", this.filter);
        // await this.fetchEvents(updFilter);
        // await this.fetchEvents(this.filter);
      } else {
        // await this.fetchEvents(this.filter);
        this.$emit("updFilter", this.filter);
      }
    },
  },
};
</script> 

<style lang="scss" scoped>
.calendar {
  max-width: 384px;
  margin: 0 auto;

  @media screen and (max-width: 1220px) {
    position: static !important;
  }
}
</style>