<template>
  <div class="calendar__day" @click="dayClick()" :class="classList">
    <span>{{ day.dayNumber }}</span>
  </div>
</template>

<script>
export default {
  name: "CalendarDay",
  props: {
    day: Object,
     freeChoice: {
      type: Boolean,
      default: false,
    },
    multiply: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classList: {
      cache: false,
      get() {
        return {
          calendar__day_weekend: this.day.isWeekend,
          "calendar__day_last-month": this.day.isLastMonth,
          "calendar__day_next-month": this.day.isNextMonth,
          calendar__day_today: this.day.isToday,
          calendar__day_current: this.day.isCurrent,
          calendar__day_hasEvents: this.day.events.length,
          calendar__day_passed: this.day.isPassed,
          calendar__day_onlyPassedEvents: this.day.onlyPassedEvents,
          calendar__day_inPeriod: this.day.inPeriod,
          calendar__day_closedToChoice: this.day.closedToChoice,
          calendar__day_periodStart: this.day.periodStart,
          calendar__day_periodEnd: this.day.periodEnd,
        };
      },
    },
  },
  methods: {
    dayClick() {
      if (this.day.events.length || this.freeChoice) {
        if (!this.day.isCurrent && !this.day.closedToChoice) {
          this.$emit("dayClick", this.day);
        } else {
          this.$emit("dayClick", false);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar {
  &__day {
    position: relative;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #1f1f1f;
    margin: 0 auto;
    transition: 0.3s;

    & span {
      position: relative;
      z-index: 2;
    }

    @media screen and (max-width: 767px) {
      font-size: 12px;
      width: 32px;
      height: 32px;
    }

    &_weekend {
      color: #9a3374;
    }

    &_today {
      background-color: #ebefee;
    }

    &_onlyPassedEvents {
     border: 1px solid #ebefee;
    }

    &_last-month,
    &_next-month {
      color: #bdbdbd;
    }

    &_hasEvents {
      border: 1px solid #da338c;
      cursor: pointer;

      &:hover {
        background-color: #ebefee;
      }
    }

    &_closedToChoice {
      opacity: .5;
      cursor: default;
    }

    &_inPeriod {
      &::before {
        position: absolute;
        height: 100%;
        width: calc(100% + 32px);
        background-color: #c76da5;
        content: "";
        display: block;
        left: -16px;
        top: 0;
      }
    }

    &_periodStart {
      &::before {
        position: absolute;
        height: 100%;
        width: 16px;
        background-color: #c76da5;
        content: "";
        display: block;
        right: 0;
        top: 0;
        mix-blend-mode: darken;
      }
    }

     &_periodEnd {
      &::before {
        position: absolute;
        height: 100%;
        width: 16px;
        background-color: #c76da5;
        content: "";
        display: block;
        left: 0;
        top: 0;
        mix-blend-mode: darken;
      }
    }

    &_current {
      background-color: #830051;
      color: #fff;
      border: 0;

      &:hover {
        background-color: #830051;
      }
    }
  }
}
</style>