<template>
  <div class="calendar__header calendar-header">
      <div class="calendar-header__arrow calendar-header__arrow_prev" @click="$emit('goPrev')">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.49691e-07 8L7 16L9.5 16L3.5 9L16 9L16 7L3.5 7L9.5 -2.84124e-07L7 -3.93402e-07L3.49691e-07 8Z" fill="currentColor"/>
        </svg>
      </div>
      <div class="calendar-header__date">
          <div class="calendar-header__month">{{formattedMonth}}</div> 
          <div class="calendar-header__year">{{year}}</div>
      </div>
      <div class="calendar-header__arrow calendar-header__arrow_next" @click="$emit('goNext')">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16 8L9 16L6.5 16L12.5 9L-3.0598e-07 9L-3.93402e-07 7L12.5 7L6.5 -2.84124e-07L9 -3.93402e-07L16 8Z" fill="currentColor"/>
        </svg>
      </div>
    </div>
</template>

<script>
export default {
  name: "CalendarHeader",
  props: {
    month: Number,
    year: Number
  },
  data: () => ({
    months: [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ]
  }),
  computed: {
    formattedMonth() {return this.months[this.month]},
    showYear() {return this.year === new Date().getFullYear()}
  }
}
</script>

<style lang="scss" scoped>
  .calendar-header {
    margin-bottom: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__month {
      color: #1F1F1F;
    }

    &__year {
      margin-left: 4px;
      font-weight: 400;
      color: #D2D2D2;
    }

    &__date {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;
    }

    &__arrow {
      cursor: pointer;
      transition: .3s;
      color: #D8DADA;

      &:hover {
        color: #830051;
      }
    }
  }
</style>